body{
    overflow: hidden;
}
.main{
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 150px;
    padding-left: 150px;
    box-sizing: border-box;

}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    flex-direction: column;
    gap:25px
}
.images{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.log{
    width: 600px;
}
.qr{
    width: 300px;
}
.location{
    border-top: 2px solid #324964;
    border-bottom: 2px solid #324964;
    text-align: center;
    font-size: 25px;
    color: #324964;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "Noto Sans JP", sans-serif;
}
.detail{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.name{
   border-bottom: 2px solid #324964; 
   border-right: 2px solid #324964;
   text-align: left;
   display: flex;
   justify-content: center;
   flex-direction: column;
   width: 100%;
}
.position{
    color: #324964;
    font-size:25px;
    padding-top: 5px;
    padding-bottom: 10px;
    font-family: "Noto Sans JP", sans-serif;
}
.identity{
    color: #324964;
    font-weight: 800;
    font-size: 40px;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    font-family: "Archivo Black", sans-serif;
}
.number{
    border-bottom: 2px solid #324964;
    text-align: right;
    color: #324964;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}
.n1{
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Noto Sans JP", sans-serif;
    text-decoration: none;
    color: #324964;
}
.n2{
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Noto Sans JP", sans-serif;
    text-decoration: none;
    color: #324964;
}
.email{
    border-bottom: 2px solid #324964;
    text-align: right;
    color: #324964;
    font-size: 25px;   
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
 
}
.e1{
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Noto Sans JP", sans-serif;
    text-decoration: none;
    color: #324964;
}
.e2{
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Noto Sans JP", sans-serif;
    text-decoration: none;
    color: #324964;
}
.info{
    width:100% ;
}
.e-address{
    width: 100%;
    display: flex;
    flex-direction: column;
}
@media (max-width: 1450px){
    .log{
        width: 400px;
    }
    .qr{
        width: 200px;
    }
    .identity{
        font-size: 30px;
    }
}
@media (max-width: 1150px){
    .log{
        width: 350px;
    }
    .location{
        font-size: 20px;
    }
    .identity{
        font-size: 26px;
    }
    .position{
        font-size: 20px;
    }
    .main{
        padding-right: 50px;
        padding-left: 50px;
    }
    .email{
        font-size: 22px;
    }
    .e1{
        font-size: 20px;
    }
    .e2{
        font-size: 20px;
    }
    .n1{
        font-size: 20px;
    }
    .n2{
        font-size: 20px;
    }
}



@media (max-width : 860px){
    .main{
        padding-right: 50px;
        padding-left: 50px;
    }

    .container{
        gap: 45px;
    }
    .images{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 45px;
    }
    

    .detail{
        display: flex;
        flex-direction: column;
    }
    .location{
        font-size: 23px;
    }
    .name{
        text-align: center;
        border-right: none;
    }
    .identity{
        font-size: 38px;
    }
    .position{
        font-size: 23px;
        padding-top: 0px;
    }
    .e-address{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid #324964;
    }
    .number{
        
        border-bottom: none;  
    }
    .n1{
        font-size: 23px;
    }
    .n2{
        font-size: 23px;
    }

    .email{
        border-bottom: none;
        text-align: right;
    }
    .e1{
        font-size: 23px;
    }
    .e2{
        font-size: 23px;
    }
    .line{
        border-left: 2px solid #324964;
    }
    .e1{
        text-align: left;
    }

}
@media (max-width : 620px){
    .log{
        width: 250px;
    }
    .qr{
        width: 300px;
    }
    .location{
        font-size: 16px;
        text-align: center;
        width: 100%;
    }
    .name{
        width: 100%;
        text-align: center;
    }
    .identity{
        font-size: 25px;
    }
    .position{
        font-size: 16px;
    }
    .n1{
        font-size: 16px;
        padding: 0px;
    }
    .n2{
        font-size: 16px;
        padding: 0px;
    }
    .e1{
        font-size: 16px;
        padding: 0px;
    }
    .e2{
        font-size: 16px;
        padding: 0px;
    }
    
    @media (max-width: 445px){
        .main{
            padding-right: 25px;
            padding-left: 25px;
        }
        .log{
            width: 200px;
        }
        .qr{
            width: 250px;
        }
        .location{
            font-size: 16px;
        }
        .identity{
            font-size: 20px;
        }
        .position{
            font-size: 16px;
        }
        .n1{
            font-size: 13px;
        }
        .n2{
            font-size: 13px;
        }
        .e1{
            font-size: 13px;
        }
        .e2{
            font-size: 13px;
        }
    }

}